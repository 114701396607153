html, body {
    font-family: "Lato", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

* {
    font-family: "Lato", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.cursor-pointer {
    cursor: pointer;
}

.before .MuiTypography-h6, .before h6 {
    padding-left: 15px;
}

.before:before {
    content: "";
    position: absolute;
    padding: 2px;
    height: 30px;
    background: #0884E2;
}

.MuiTableCell-root {
    padding: 0 0 0 16px !important;
}

table.details, table.details-2 {
    width: 100%;
}

table.details th {
    width: 20%;
}

table.details td {
    width: 30%;
}

table.details tr, table.details th, table.details td, table.details-2 {
    padding: 10px;
    border: 1px solid rgb(128 128 128 / 15%);
}

table.details-2 th {
    padding: 10px;
    border: 1px solid rgb(128 128 128 / 15%);
}

table.details-2 td {
    padding-left: 10px;
    border: 1px solid rgb(128 128 128 / 15%);
}

.form-action .MuiDialog-container .MuiPaper-root {
    min-width: 30%;
}

.msg-box {
    border-radius: 50%;
    padding: 5px 12px !important;
}

.chip-cold {
    background-color: #f1a649 !important;
}

.chip-loss {
    background-color: #ef6860 !important;
}

.chip-hot {
    background-color: #429ce1 !important;
}

.chip-warm {
    background-color: #6368c0 !important;
}

.chip-received {
    background-color: #2fad3b !important;
}

.grid-border {
    border: 1px solid rgb(128 128 128 / 15%);
}

.notification-badge-unread {
    font-size: 26px;
    color: #ef6860;
}

.notification-badge-read {
    font-size: 26px;
    color: transparent;
}

/* Scrollbar */

.MuiDrawer-paperAnchorLeft::-webkit-scrollbar {
    display: none;
    width: 7px;
    height: 7px;
}

.MuiDrawer-paperAnchorLeft:hover::-webkit-scrollbar {
    display: block;
}

/* Track */

.MuiDrawer-paperAnchorLeft::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 5px;
}

/* Handle */

.MuiDrawer-paperAnchorLeft::-webkit-scrollbar-thumb {
    background: #E0E0E0;
    border-radius: 5px;
}

/* Handle on hover */

.MuiDrawer-paperAnchorLeft::-webkit-scrollbar-thumb:hover {
    background: #919191;
}

/* END: Scrollbar */

.mobile-view {
    display: none;
}

.web-view {
    display: block;
}

.MuiListItem-gutters {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

@media (max-width: 768px) {
    .mobile-view {
        display: block;
    }

    .web-view {
        display: none;
    }

    .MuiListItem-gutters {
        padding-left: 2px !important;
        padding-right: 12px !important;
    }
}

body {
    background-color: #F9FBFD !important;
}

.MuiListItemText-root {
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0.7px !important;
    line-height: 25.48px !important;
    text-decoration: none solid rgb(87, 89, 98) !important;
}

.notification-title {
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0.7px !important;
    line-height: 21px !important;
    text-decoration: none solid rgb(68, 68, 68) !important;
    text-align: center !important;
}

.bg-notification {
    border-radius: 22px;
    padding: 4px;
    padding-top: 2px;
    background-color: #097CD4;
}

.view-profile {
    /* margin-top: 10px; */
    box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%);
}

.view-profile .border {
    margin: 0 15px 0 15px;
    padding: 12px;
    border: 1px solid;
    border-color: #ebedf2 !important;
}

.view-profile .border:hover {
    background-color: rgba(0, 0, 0, .075);
}

.badge-success {
    border-radius: 5px;
    padding: 7px 12px;
    background-color: #31CE36 !important;
}

.p-unset {
    padding: unset !important;
}

.view-profile .header-bg {
    height: 100px;
    background-color: #D3EAFD;
}

.view-profile .profile-image {
    margin-top: -30px;
    text-align: center;
}

.view-profile .profile-image img {
    border-radius: 50px;
}

.btn-profile {
    margin-top: -6px !important;
    text-transform: unset !important;
    font-size: 11px;
    background-color: #0884E2;
}

.ago {
    font-size: 11px !important;
    letter-spacing: 0.7px !important;
    line-height: 16.5px !important;
    text-decoration: none solid rgb(125, 140, 149) !important;
}

.bg-auth {
    min-height: 80vh;
    background-image: url(../images/bg-auth.png);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: bottom;
}

.MuiContainer-maxWidthXs {
    margin-top: 8vw;
    background-color: #F1F4FB;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    border: 1px solid #ccdada;
}

a:hover {
    text-decoration: unset !important;
}

.MuiListItemIcon-root {
    min-width: 55px !important;
    color: unset !important;
}

div[class^="MUIDataTableBodyCell-root-"] {
    font-size: 14px !important;
}

div[class^="MuiChip-root-"] {
    color: #fff !important;
}

.MuiAppBar-colorPrimary {
    background-color: #097CD4 !important;
}

.MuiListItem-root {
    color: #8D9498 !important;
}

.bg-1 {
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    height: 64px;
    background-color: #0884E2;
}

.makeStyles-drawerOpen-7 .MuiListItemIcon-root {
    min-width: 40px !important;
}

.MuiList-root {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.MuiDrawer-paperAnchorDockedLeft {
    border-right: unset !important;
    box-shadow: 4px 4px 10px rgb(69 65 78 / 6%) !important;
}

.MuiPaper-elevation4 {
    box-shadow: unset !important;
}

.MuiBadge-anchorOriginTopRightRectangle {
    background-color: #31CE36;
}

.MuiList-root a.active .MuiButtonBase-root {
    color: #FFFFFF !important;
    background-color: #0884E2 !important;
    border-radius: 5px !important;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px #1571e898 !important;
}

.makeStyles-drawerOpen-7 {
    box-shadow: 4px 4px 10px rgb(69 65 78 / 6%);
}

a.active .MuiListItemText-primary {
    text-decoration: underline;
    text-decoration-color: #0884E2 !important;
}

@media (min-width: 600px) {
    .makeStyles-drawerClose-8 {
        width: 85px !important;
    }
}

.menu-logo {
    width: 25%;
    padding: 20px 0 20px 0;
}

.table-menu td svg {
    color: #fff;
}

.makeStyles-nested-13 {
    padding-left: 16px !important;
}

.MuiCollapse-wrapperInner {
    width: 120% !important;
}

.makeStyles-nested-1439 {
    padding-left: 16px;
}

.breadcrumb1 {
    padding-left: 2rem !important;
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    margin-left: -30px !important;
    margin-top: -30px !important;
    margin-right: -24px !important;
    background-color: #C7E4FB !important;
}

.breadcrumb1 p.MuiTypography-root {
    color: #000;
    font-size: 22px !important;
    font-weight: 600 !important;
}

.breadcrumb1 p {
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px !important;
    font-weight: 500 !important;
}

.entry-div {
    margin-top: -50px !important;
}

.MuiPaper-elevation1 {
    box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%) !important;
    border: 0px !important;
}

.MuiTabs-flexContainer {
    background-color: #fff;
}

.MuiTabs-root {
    box-shadow: 1px -3px 15px 0px rgb(69 65 78 / 10%);
    border: 0px;
}

.table-2 {
    box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%);
    border: 0px;
}

.table-2 .MuiButton-label {
    color: #495057;
    font-weight: 600;
}

.table-2 th {
    color: #495057;
    font-weight: 600;
    background-color: #E9ECEF !important;
}

.table-2 th, .table-2 td {
    border: 1px solid #E9ECEF;
}

.MuiTab-wrapper {
    font-weight: 600;
}

.table-2 .MuiButtonGroup-grouped {
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #E5F2F9;
    border-radius: 30px;
}

.table-2 div[class^="MuiButtonGroup-root-"] {
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #E5F2F9;
    border-radius: 30px;
}

.table-2 .MuiIconButton-root {
    padding: 8px;
}

.table-2 .MUIDataTable-tableRoot-21 td .MuiSvgIcon-root {
    color: #0884E2;
}

.form-heading {
    font-size: 20px;
    padding-top: 5px;
    padding-left: 15px;
}

.before:before {
    content: "";
    position: absolute;
    padding: 2px;
    height: 30px;
    background: #097CD4;
}

.table-2 .MUIDataTableBodyCell-stackedCommon-72 {
    min-height: 36px !important;
    vertical-align: middle;
}

.MuiToggleButton-root.Mui-selected {
    color: #0884e2 !important;
    border: 1px solid #0884e2 !important;
    border-color: #0884e2 !important;
    background-color: #E5F2F9 !important;
}

.MuiButton-containedSecondary {
    background-color: #dc004e !important;
}

.MuiTypography-gutterBottom {
    margin-bottom: unset !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #fff !important;
    background-color: #0884E2;
}

.MuiTab-fullWidth {
    border: 1px solid #eeeeee !important;
}

.MuiTab-wrapper {
    text-transform: capitalize !important;
}

.MuiBadge-colorSecondary {
    color: #0884e2 !important;
    background-color: #ECD198 !important;
}

.MuiListItemText-primary {
    font-size: 15px !important;
    font-weight: 500 !important;
    letter-spacing: 0.7px !important;
    line-height: 25.48px !important;
}

.sidebar .sidebar-wrapper, .sidebar[data-background-color="white"] .sidebar-wrapper {
    -webkit-box-shadow: 4px 4px 10px rgb(69 65 78 / 6%);
    -moz-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
    box-shadow: 4px 4px 10px rgb(69 65 78 / 6%);
}

.img-featured {
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

.btn-delete {
    color: #dc004e !important;
}

.text-created {
    color: #6BC650 !important;
}

.text-replied {
    color: #105652 !important;
}

.text-assigned {
    color: #FFAB4C !important;
}

.text-closed {
    color: #CD1818 !important;
}

.text-appointment, .text-booked {
    color: #26A1ED !important;
}

.text-serviced {
    color: #396EB0 !important;
}

.MuiPickerDTTabs-tabs {
    color: #097cd4 !important;
}

.text-gujarati {
    padding-top: 10px;
    font-size: 17px;
}

.rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.rotate-180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.file-register .MuiBox-root {
    padding: unset !important;
}

table.details.guj-text-included th {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.7px;
    line-height: 25.48px;
}

.note-card {
    position: relative;
    background: #DEEFFD;
    min-width: 45%;
    padding: 10px 15px;
    border-radius: 6px;
    border: 1px solid #DEEFFD;
    float: left;
    left: 20px;
}

.note-card hr {
    margin-top: 5px;
}

.note-card h6 {
    font-weight: 600;
}

.note-card span {
    margin-left: 15px;
    padding-top: 3px;
    font-size: 13px;
    opacity: 0.6;
}

.note-card::before {
    content: '';
    position: absolute;
    visibility: visible;
    top: 20px;
    left: -10px;
    border: 10px solid transparent;
    border-top: 10px solid #DEEFFD;
}

.note-card::after {
    content: '';
    position: absolute;
    visibility: visible;
    top: 0px;
    left: -10px;
    border: 10px solid transparent;
    border-bottom: 10px solid #DEEFFD;
    clear: both;
}

.card-checkbox {
    padding: 20px;
    background-color: #C7E4FB5e !important;
    box-shadow: 0px 0px 0px 0px rgb(69 65 78 / 41%) !important;
    border: 0px !important;
}

.t-hight {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.link-text {
    cursor: pointer;
    color: #0884e2;
    font-size: 15px;
}

.txt-guj {
    font-family: 'Noto Sans Gujarati', sans-serif !important;
}
